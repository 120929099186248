<template>
    <div>
        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color" top>
            <v-icon>{{ snackbar.icon }}</v-icon>
            <span class="white--text ml-3">{{ snackbar.text }}</span>

            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" text v-bind="attrs" @click="snackbar.show = false">
                    Fechar
                </v-btn>
                <v-btn v-if="snackbar.confirmationBtn" color="secondary" text @click="snackbar.confirm()">
                    Confirmar
                </v-btn>
            </template>
        </v-snackbar>
        <v-list>
            <v-card class="mx-auto mb-5" outlined v-for="item in notifications" :key="item.id">
                <v-list-item outlined three-line>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ item.created_at_formated }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-body-1 my-5">
                            {{ item.message }}
                        </v-list-item-subtitle>
                        <div class="text-overline mb-0">{{ item.email }}</div>
                        <div class="text-overline mb-0" v-if="item.contact">
                            <v-icon small class="mr-1" v-if="item.is_whatsapp">mdi-whatsapp</v-icon>{{ item.contact }}
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-list>
    </div>
</template>

<script>
export default {
    data: () => ({
        notifications: [],
        snackbar: {
            color: "success",
            show: false,
            icon: "mdi-check-circle",
            confirmationBtn: false,
            confirm: Function,
            negate: Function,
            text: "",
            timeout: 2000,
        },
    }),
    methods: {
        getNotifications() {
            this.$api
                .get("interests/news", {
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get("token")}`,
                    },
                })
                .then((res) => {
                    this.notifications = res.data.data
                })
                .catch((err) => {
                    this.snackbar = {
                        show: true,
                        color: 'error',
                        text: `Ops! Ocorreu um erro: ${err}`,
                        icon: 'mdi-alert-octagon',
                        timeout: 2000,
                    }
                })
        }
    },
    mounted() {
        this.getNotifications()
    }
}
</script>